import React from "react";
import { Link } from "gatsby";

import PropTypes from "prop-types";

const Component = ({ isAuth,isAdmin }: any) => {
  const userId = isAuth && isAuth.id;
  const myPostsUrl = `/posts/filters/user:${userId}`
  return (
    isAuth ? (
      <div className="sidebar-widget sidebar-meta">
        <h3 className="sidebar-heading">Meta</h3>
        <ul className="list-unstyled">
          <li>
            <Link to="/posts/edit/new">Create New Post</Link>
            <Link to={myPostsUrl}>View My Posts</Link>
            {isAdmin && <Link to="/posts/filters/isPublished:false">View Unpublished Posts</Link>}
          </li>
        </ul>
      </div>
    ):null
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
