import React from "react";
import PropTypes from "prop-types";

const Components = ({children}) => {
  return (
    <div>{children}</div>
  );
};

Components.propTypes = {};
Components.defaultProps = {};

export default Components;
