//flow
import React, { Fragment } from "react";
import { Tools, ApiTools } from "../../../components";
import MenuItems from "./menu";
import MetaMenu from "./meta";
import SubscriptionForm from './subscribe-form'

import PropTypes from "prop-types";
type Type = {
  showPager?: boolean,
  isAuth?: any,
  isAdmin?: any,
  hideFilters?: boolean,
  children: any
};


const Component = ({ children, showPager, isAuth, hideFilters,isAdmin }: Type) => {
  return (
    <section id="blog-single-cection" className="blog-list-grid">
      <div className="blog-list-inner tt-wrap">
        <div className="  no-margin">
          <div className="col-md-8 no-padding-left no-padding-right">
            <div className="  isotope-wrap">{children}</div>
            <div className="row" />
            <div>{showPager && <ApiTools.Paginator />}</div>
          </div>
          <div className="col-md-4 no-padding-left no-padding-right">
            <div className="sidebar sidebar-right">
              <div className=" ">
                <div className="col-sm-12">
                  <div className="sidebar-widget sidebar-social">
                    <h3 className="sidebar-heading">Follow</h3>
                    <Tools.SocialButtons
                      contact={null}
                      classes={"btn-lg"}
                      isFollow
                    />
                  </div>
                </div>
                {!hideFilters && (
                  <Fragment>
                    <div className="col-sm-12">
                      <div className="sidebar-widget sidebar-search">
                        <h3 className="sidebar-heading">Search</h3>
                        <ApiTools.SearchInput />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6">
                      <div className="sidebar-widget sidebar-categories">
                        <h3 className="sidebar-heading">Categories</h3>
                        <MenuItems
                          showCount
                          name={"category"}
                          attribute={"_category"}
                          classes={"list-unstyled"}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6">
                      <div className="sidebar-widget sidebar-tags">
                        <h3 className="sidebar-heading">Popular Tags</h3>
                        <MenuItems
                          name={"tags"}
                          transformItems={items =>
                            items.map(item => ({
                              ...item,
                              label: `#${item.label}`
                            }))
                          }
                          attribute={"_tags"}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
                <div className="col-md-12 col-sm-6">
                  <SubscriptionForm/>
                </div>
                <div className="col-md-12 col-sm-6">
                  <MetaMenu isAuth={isAuth} isAdmin={isAdmin} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Component.propTypes = {};
Component.defaultProps = {
  showPager: true
};

export default Component;
