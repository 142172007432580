//flow
import React from "react";
import { PostDetail } from "../../components";
import { compose } from "recompose";

import {PostTemplate} from "../../page-components/posts";
import { PostDataProvider } from "../../providers/air-table";
import { withAlgoliaConnect, withAuth } from "../../HOC";
import { userHasId } from '../../util';
import type {  IPost } from "../../definitions";

const SOURCE_TABLE = "Posts";
const INDEX_NAME = "prod_BLOG";


type Type = {
  postId: string,
  isAuth: boolean
};

const Component = ({ postId, isAuth }: Type) => {
  const adminUrl =`/posts/edit/${postId}`;
  const isAuthFunc = (postData:IPost)=>userHasId(postData.user)
  return (
    <PostTemplate hideFilters isAuth={isAuth} showPager={false}>
      <PostDetail
        isAuth={isAuthFunc}
        adminUrl={adminUrl}
        provider={PostDataProvider}
        table={SOURCE_TABLE}
        dataId={postId}
      />
    </PostTemplate>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default compose(
  withAlgoliaConnect(INDEX_NAME),
  withAuth(),
)(Component);
