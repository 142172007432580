//flow
import React from "react";
import withLifeCycle from "@hocs/with-lifecycle";
import { compose } from "recompose";
import { autorun, reaction } from "mobx";

import store from "../../stores";
import { withAuth } from "../../HOC";

const Component = ({ isAuth, component: MyComponent, ...rest }: any) => {
  return isAuth ? (
    <div>
      <MyComponent isAuth={isAuth} {...rest} />
    </div>
  ) : (
    <div className="row" />
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default compose(
  withAuth(),
  withLifeCycle({
    onDidMount() {
      autorun(() => {
        if (!store.uIStore.user) {
          store.uIStore.authenticate("login");
        }
      });
    }
  })
)(Component);
