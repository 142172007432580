//flow
import React from "react";
import { Forms } from "../../../components";

import PropTypes from "prop-types";

const ButtonContent = () => (
  <button type="submit" className="btn btn-primary btn-block btn-lg">
    Subscribe
  </button>
);

const Component = props => {
  return (
    <div className="sidebar-widget sidebar-subscribe">
      <h3 className="sidebar-heading">Subscribe</h3>
      <p className="small text-gray">
        Subscribe to our newsletter and stay updated on the latest news! Do not
        worry, we will not send spam.
      </p>
      <Forms.SubscriptionForm
        formClasses=''
        id="sidebar-subscribe-form"
        ButtonContent={ButtonContent}
        inputContainerClasses={"no-margin"}
      />
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
