import React, { useEffect, useState } from "react";
import { PostEditor } from "../../components";
import { userHasRole } from "../../util";
import { withHandlers } from 'recompose';
import { navigateTo } from 'gatsby';



const Component = ({ isAuth, postId,afterSave }) => {
  const user = isAuth ? isAuth.id : "";
  const publishedBy =
    isAuth && isAuth.user_metadata ? isAuth.user_metadata.full_name : "";

  return (
    <div className="tt-wrap margin-top-40">
      <PostEditor
        hideCategory={!userHasRole("admin")}
        hidePublish={!userHasRole("admin")}
        user={user}
        publishedBy={publishedBy}
        dataId={postId}
        afterSave={afterSave}
      />
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default withHandlers({
  afterSave:()=>({data:{objectID}})=>{
    navigateTo(`/posts/${objectID}`)
  }
})(Component);
