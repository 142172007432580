//flow
import React from "react";
import { connectMenu } from "react-instantsearch-dom";
import { withHandlers,compose } from 'recompose';

import {Link} from 'gatsby'

import PropTypes from "prop-types";
type Type = {
  classes?: string,
  items?: any,
  showCount: boolean,
  onClick: Function,
  name: string,
};

const Component = ({ items, classes,showCount,onClick,name }:Type) => (
  <ul className={classes}>
    {items.map(item => (
      <li key={item.value+name}>
        <Link
          to="#"
          style={{ fontWeight: item.isRefined ? "bold" : "" }}
          onClick={(e)=>onClick(e,item)}
        >
          {item.label}
          {showCount && <span>{item.count}</span>}
        </Link>
      </li>
    ))}
  </ul>
);

Component.propTypes = {};
Component.defaultProps = {};

export default compose(
  connectMenu,
  withHandlers({
    onClick:({refine})=>(e,item)=>{
      e.preventDefault();
      refine(item.value)
    }
  })

)(Component);
